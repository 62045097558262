import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import "./styles.scss";
import "./mobile.scss";
import {
  Button,
  ButtonIcon,
  MainHeader,
  OfferCard,
  OfferShimmerCard,
  PreLoader,
  RecentViewOffers,
  SectionHeader,
} from "../../components";
import {
  getBrowserMeta,
  getGeoLocation,
  getInsight,
  getIPAddress,
  getLocationName,
  setInsight,
  userDetails,
  userLogOut,
} from "../../utils";
import MerchantPortalComponent from "../merchantPortal";
import CustomerPortalComponent from "../customerPortal";
import {
  addOfferToUserFavorite,
  getLoginDetails,
  getLoginUserDetailsById,
  getSessionValidateStatus,
  getUserById,
  removeOfferFromUserFavorite,
} from "../../service/userApi";
import { createNewInsight } from "../../service/insightApi";
import * as actionTypes from "../../store/actions/types";
import { connect } from "react-redux";
import {
  createNewImpression,
  getBank,
  getCategory,
  getOfferByBankId,
  getOfferByCategoriesBanksMultiple,
  getOfferByCategoryId,
  getOfferByPage,
  getOfferCount,
  getOfferCountMultiple,
} from "../../service";
import ViewOffer from "../viewOffer";
import { Menu } from "antd";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { getAllCategory } from "../../service/categoryApi";
import Footer from "../Footer";
import errorPage from "../errorPage";
import {
  FilterViewModal,
  MobileProfileViewModal,
  MobileSignInModal,
  MoreInfo,
  OfferViewModal,
  ProfileView,
} from "../modal";
import { openNotification } from "../../config/notification";
import RestaurantAdCard from "../advertiesment-2";
import ViewBank from "../viewBank";
import RecentViewBank from "./template/RecentViewBank";
import MobileRecentViewBank from "./template/mobileRecentViewbank";
import { Helmet } from "react-helmet";

class ConsoleComponent extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      isLoading: true,
      viewPointWidth: 0,
      viewPointHeight: 0,
      isScrollTop: false,
      currentPageNo: 1,
      settings: {
        variableWidth: true,
        className: "slider variable-width",
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 10,
        slidesToScroll: 1,
        initialSlide: 0,
        cssEase: "cubic-bezier(0.33, 1, 0.68, 1)",
        nextArrow: (
          <div>
            <ButtonIcon
              buttonIcon={<RiArrowRightSLine />}
              type={"round"}
              size={"md"}
            />
          </div>
        ),
        prevArrow: (
          <div>
            <ButtonIcon
              buttonIcon={<RiArrowLeftSLine />}
              type={"round"}
              size={"md"}
            />
          </div>
        ),
      },
      dataForm: {
        bankId: {
          key: "bankId",
          elementType: undefined,
          elementConfig: {},
          locationUrl: "",
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: undefined,
        },
        categoryID: {
          key: "categoryID",
          elementType: undefined,
          elementConfig: {},
          locationUrl: "",
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: undefined,
        },
        filter: {
          key: "filter",
          elementType: undefined,
          elementConfig: {
            type: "text",
            placeholder: "Bank Name,category",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: undefined,
        },
      },
      offerId: "",
      categoryData: [],
      isLoadingCategory: [],
      bankData: [],
      isLoadingBank: false,
      pageViewCount: 10,
      categoryIdsList: [],
      bankIdsList: [],
      userFavoritesList: [],
      sessionUser: null,
      isMobileVerification: true,
      favoriteOffersList: [],
      isFavoriteLoading: false,
      lastScrollY: 0,
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleOnScroll = this.handleOnScroll.bind(this);
    //this.fetchInsight = this.fetchInsight.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = async (e) => {
    //console.log("scroooolling...");
    // http://stackoverflow.com/questions/9439725/javascript-how-to-detect-if-browser-window-is-scrolled-to-bottom
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.clientHeight;

    // var scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
    //console.log("scrollTop", scrollTop);
    if (scrollTop >= 200) {
      this.setState({
        isScrollTop: true,
      });
    }

    if (scrollTop + clientHeight >= scrollHeight - 5) {
      //console.log("Scrolled to the bottom!");
      // Add the logic for fetching more data or doing something when at the bottom
    }

    const { lastScrollY, viewPointHeight } = this.state;
    let currentScrollY = scrollTop;
    let { reachBottom } = this.state; // Retrieve reachBottom from state
    let { hasReachedBottom } = this.state; // Flag to prevent multiple executions

    const scrolledHeight = window.innerHeight + window.scrollY;
    const documentHeight = document.body.offsetHeight;
    const windowHeight = window.scrollY;

    if (scrollTop + clientHeight >= scrollHeight - 5) {
      //console.log('Scroll', {currentScrollY, windowHeight});
    }

    // // Check if user has scrolled to the bottom
    // if (window.innerHeight + document.documentElement.scrollTop
    //     === document.documentElement.offsetHeight) {
    //   console.log('Scroll', {currentScrollY, windowHeight});
    // } else {
    // }

    // // Detect if the user has scrolled beyond the viewPointHeight
    // if ((currentScrollY >= viewPointHeight + 1000 && !hasReachedBottom) || currentScrollY === 0) {
    //   // Update reachBottom only once when the condition is met
    //   reachBottom = currentScrollY;
    //   // Set the flag to prevent multiple executions
    //   this.setState({
    //     reachBottom,
    //     hasReachedBottom: true, // Ensure this block executes only once
    //   });
    // }
    //
    // console.log({ viewPointHeight }, { currentScrollY }, { reachBottom });
    // // Detect if the user is scrolling down and has reached the bottom of the page
    // if (currentScrollY - reachBottom >= viewPointHeight) {
    //   if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
    //     reachBottom = currentScrollY;
    //
    //     // Reset the flag when the user reaches the bottom, allowing this logic to run again
    //     this.setState({
    //       reachBottom,
    //       hasReachedBottom: false, // Allow the reachBottom condition to run again later
    //     });
    //
    //     // Fetch more offers if certain conditions are met
    //     if (
    //       this.state.bankIdsList?.length > 0 ||
    //       this.state.categoryIdsList?.length > 0
    //     ) {
    //       await this.getMoreOfferMultiple();
    //     } else {
    //       await this.getMoreOffer();
    //     }
    //   }
    // }
    //
    // // Update the last scroll position
    // this.setState({ lastScrollY: currentScrollY });

    if (scrollTop <= 100) {
      this.setState({
        isScrollTop: false,
      });
    }

    if (scrollTop + clientHeight >= scrollHeight - 5) {
      //console.log("Scrolled to the bottom!");
      // Add the logic for fetching more data or doing something when at the bottom
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll, true);
    window.removeEventListener("resize", this.updateWindowDimensions, true);
    // window.addEventListener('scroll', this.listenToScroll);
  }

  async onClickChangeType(value, inputIdentity) {
    let eventForm = {
      ...this.state.dataForm,
    };
    let updatedFormElement = {
      ...eventForm[inputIdentity],
    };
    updatedFormElement.value = value;
    eventForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in eventForm) {
      formIsValid = eventForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: eventForm, formIsValid: formIsValid });

    if (inputIdentity === "bankId") {
      await this.getOfferByBankId(value);
    }
  }

  async componentDidMount() {
    console.log("componentDidMount");
    window.addEventListener("resize", this.updateWindowDimensions, true);
    window.addEventListener("scroll", this.handleOnScroll, true);

    this.updateWindowDimensions();
    this.handleOnScroll();
    this.props.clearFilterOptions();

    console.log("offerCount", this.props.offerData?.length);
    if (this.props.offerData?.length === 0) {
      await this.getOffer();
    }

    //=========== is available session user ===========
    let sessionUser = userDetails();
    console.log("sessionUser console:::", sessionUser);
    if (sessionUser?.userId && sessionUser.userType) {
      console.log("sessionUser:::", sessionUser);
      this.setState({ sessionUser: sessionUser });
      this.props.onAddLoginUser(sessionUser);
      let sessionStatus = await getSessionValidateStatus();

      console.log("sessionStatus", sessionStatus);

      if (sessionStatus?.data?.status) {
        console.log("valid session");
        // let favoriteData = await getFavoriteOfferByUser(sessionUser?.userId);
        let user = await getUserById(sessionUser?.userId);
        console.log("userData", user);
        if (user?.status) {
          this.props.onAddLoginUser(user?.data);
          this.props.userLogged();
          this.setState({
            isMobileVerification: user?.data?.isMobileVerification,
            //     userFavoritesList: user?.data?.favorites,
            //     // favoriteOffersList:
            //     //   favoriteData?.data && favoriteData?.data[0]?.favorites,
          });
        }
      } else {
        console.log("invalid session");
        userLogOut();
        this.props.onClickLogOut();
      }
    }

    const { location } = this.props;
    await this.getCategory();
    await this.getBank();

    let _this = this;

    //================= User insight =============================
    //removeInsight();
    let browserMeta = getBrowserMeta();
    let insightBody = {};

    async function handleInsight() {
      try {
        // Get the geolocation
        const position = await getGeoLocation();
        const { latitude, longitude, accuracy, timestamp } = position?.coords;

        // Wait for all promises to resolve
        const [location, ip] = await Promise.all([
          getLocationName(latitude, longitude),
          getIPAddress(),
        ]);

        // Construct the insight body
        const insightBody = {
          insightId: null,
          browserMeta,
          accuracy,
          latitude,
          longitude,
          timestamp,
          location,
          ip,
          user: sessionUser?.userId,
        };

        console.log("userInsight_insightBody:", insightBody);
        const insight = await getInsight();
        console.log("Insight found", insight);
        // Additional logic for when the insight is found
        if (insight?.insightId) {
          console.log("userInsight_insight_found", insight);
          insightBody.insightId = insight.insightId;
        }
      } catch (error) {
        console.log("No insight found", error);
        try {
          insightBody.insightId = null;
          const insightResponse = await createNewInsight(insightBody);
          console.log("Created new insight:", insightResponse);
          await setInsight(insightResponse?.data?.token);
          // Additional logic for when the insight is not found and a new one is created
        } catch (creationError) {
          console.error("Error creating new insight:", creationError);
          // Handle the error that occurred during the creation of a new insight
        }
      }
    }

    /**
     * Fetches user geolocation, resolves location name and IP address, and creates or updates user insight.
     * If any errors occur during the process, they are caught and logged to the console.
     */
    async function userInsight() {
      try {
        // Fetch existing insight if it exists
        const existingInsight = false;
        await handleInsight();
      } catch (error) {
        console.error("Error in userInsight:", error);
      }
    }

    await userInsight();
    //===================== End =============================
  }

  async getCategory() {
    if (this.state.categoryData.length === 0) {
      let res = await getCategory();
      let categoryData = res.status ? await this.syncCategory(res.data) : [];
      this.props.setCategory(categoryData);
      this.setState({
        categoryData,
        isLoadingCategory: false,
      });
    }
  }

  async getBank() {
    //this.setState({isLoadingBank: true});
    if (this.state.bankData.length === 0) {
      let res = await getBank();
      let bankData = res.status ? await this.syncBank(res.data) : [];
      this.props.setBank(bankData);
      this.setState({
        bankData,
        isLoadingBank: false,
      });
    }
  }

  async syncBank(res) {
    let resList = [];
    for (let data of res) {
      resList.push({
        ...data,
        isActive: false,
      });
    }
    return resList;
  }

  async syncCategory(res) {
    let resList = [];
    for (let data of res) {
      resList.push({
        ...data,
        isActive: false,
      });
    }
    return resList;
  }

  async getOffer() {
    //this.setState({offerData: [], isLoading: true});
    this.props.onLoadOffers();
    let res = await getOfferByPage(1, this.state.pageViewCount);
    let resCount = await getOfferCount("accepted");

    console.log("resCount", resCount?.data[0]?.totalCount);
    if (res) {
      this.props.onCompleteOffers({
        data: (await res) && res.data,
        totalCount: resCount?.data[0]?.totalCount,
        pageNo: 1,
      });
    }
  }

  async updateOffers() {
    //this.setState({offerData: [], isLoading: true});
    // this.props.onLoadOffers();
    if (
      this.state.bankIdsList?.length > 0 ||
      this.state.categoryIdsList?.length > 0
    ) {
      await this.getOffersFilterByMultiple();
    } else {
      let res = await getOfferByPage(1, this.state.pageViewCount);
      let resCount = await getOfferCount("accepted");

      console.log("resCount", resCount?.data[0]?.totalCount);
      if (res) {
        this.props.onCompleteOffers({
          data: (await res) && res.data,
          totalCount: resCount?.data[0]?.totalCount,
          pageNo: 1,
        });
      }
    }
  }

  async getOfferByBankId(bankId) {
    this.setState({
      offerData: [],
      isLoading: true,
    });
    let res = await getOfferByBankId(
      this.props.bankIdList,
      this.state.currentPageNo,
      this.state.pageViewCount
    );
    this.setState({
      offerData: res && res.data,
      isLoading: false,
    });
  }

  async getOfferByCategoryId(categoryId) {
    this.setState({
      offerData: [],
      isLoading: true,
    });
    let res = await getOfferByCategoryId(
      this.props.categoryIdList,
      this.state.currentPageNo,
      this.state.pageViewCount
    );
    this.setState({
      offerData: res && res.data,
      isLoading: false,
    });
  }

  onClickLogOut = () => {
    let _this = this;
    this.props.history.push("/");
    this.setState({
      isMobileVerification: true,
      userFavoritesList: [],
    });
    this.props.onClickLogOut();
    userLogOut(function (res) {
      console.log("logout------");
      console.log(res);
      window.location.reload();
    });
  };

  onClickGoToPage = (linkPage) => {
    if (linkPage === "portal" && this.props.logInUser.user.userType === "1") {
      this.props.history.push("/console/customer/profile");
    }
    if (linkPage === "portal" && this.props.logInUser.user.userType === "2") {
      this.props.history.push("/console/merchant/profile");
    }
    if (linkPage === "/") {
      this.props.history.push("/");
    }
    if (linkPage === "/offer-view") {
      this.props.history.push("/credit-card-offer");
    }
    if (linkPage === "profile" && this.props.logInUser.user.userType === "1") {
      this.props.history.push("/console/customer/profile");
    }
    if (linkPage === "profile" && this.props.logInUser.user.userType === "2") {
      this.props.history.push("/console/merchant/profile");
    }
    if (
      linkPage === "dashboard" &&
      this.props.logInUser.user.userType === "2"
    ) {
      this.props.history.push("/console/merchant/dashboard");
    }
    if (linkPage === "offer" && this.props.logInUser.user.userType === "1") {
      this.props.history.push("/console/customer/offer");
    }
    if (linkPage === "offer" && this.props.logInUser.user.userType === "2") {
      this.props.history.push("/console/merchant/offer");
    }
    if (
      linkPage === "subscription" &&
      this.props.logInUser.user.userType === "2"
    ) {
      this.props.history.push("/console/merchant/subscription");
    }
  };

  async getMoreOffer() {
    this.setState({
      isLoading: true,
    });
    this.props.onLoadingMoreOffers();
    let pageNo = this.state.currentPageNo + 1;
    this.setState({ currentPageNo: pageNo });
    let res = await getOfferByPage(pageNo, this.state.pageViewCount);
    console.log("more_data", res);
    if (await res) {
      this.props.onLoadingMoreOffersCompleted();
      this.props.loadMoreOffers({
        offerData: this.props.offerData,
        data: res && res.data,
        totalCount: this.props.totalCount,
        pageNo: pageNo,
      });
      this.setState({
        isLoading: false,
      });
    }
  }

  async getOffersFilterByMultiple() {
    this.setState({
      offerData: [],
      loading: true,
    });
    let body = {
      bankIds: this.state.bankIdsList,
      categoryIds: this.state.categoryIdsList,
    };

    let res = await getOfferByCategoriesBanksMultiple(
      body,
      this.state.currentPageNo,
      this.state.pageViewCount
    );
    let resCount = await getOfferCountMultiple("accepted", body);
    if (res && res.status) {
      console.log("result::", res);
      console.log("resCount::", resCount);
      this.props.onCompleteOffers({
        offerData: res && res ? res.data : [],
        data: (await res) && res.data,
        totalCount: resCount?.data[0]?.totalCount,
        pageNo: 1,
      });
      // this.props.setOffersByFilter({
      //   offerData: res && res ? res.data : [],
      //   data: res && res ? res.data : [],
      //   totalCount: res?.totalCount || 0,
      //   pageNo: this.state.currentPageNo,
      // });
      this.setState({
        offerData: res && res.data,
        loading: false,
      });
    } else {
      this.setState({
        offerData: res && res.data,
        loading: false,
      });
    }
  }

  async getMoreOfferMultiple() {
    this.setState({
      isLoading: true,
    });
    this.props.onLoadingMoreOffers();
    let pageNo = this.state.currentPageNo + 1;
    this.setState({ currentPageNo: pageNo });
    let body = {
      bankIds: this.state.bankIdsList,
      categoryIds: this.state.categoryIdsList,
    };

    let res = await getOfferByCategoriesBanksMultiple(
      body,
      pageNo,
      this.state.pageViewCount
    );
    console.log("more_data", res, pageNo);
    if (await res) {
      this.props.onLoadingMoreOffersCompleted();
      this.props.loadMoreOffers({
        offerData: this.props.offerData,
        data: res && res.data,
        totalCount:
          res && res?.data?.length > 0
            ? parseInt(this.props.totalCount) - parseInt(res.data.length)
            : res?.data?.length,
        pageNo: pageNo,
      });
      this.setState({
        isLoading: false,
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    // Check if bankIdList or categoryIdList have changed
    if (
      prevProps.bankIdList !== this.props.bankIdList ||
      prevProps.categoryIdList !== this.props.categoryIdList
    ) {
      if (
        this.props.bankIdList?.length > 0 ||
        this.props.categoryIdList?.length > 0
      ) {
        // Update state with new bankIdList and categoryIdList
        const bankIdsList = this.props.bankIdList;
        const categoryIdsList = this.props.categoryIdList;

        this.setState({ currentPageNo: 1 });
        this.setState({ bankIdsList, categoryIdsList });
      } else if (
        (this.props.bankIdList?.length === 0 &&
          this.props.categoryIdList?.length === 0) ||
        this.props.offerData?.length === 0
      ) {
        this.setState({ bankIdsList: [], categoryIdsList: [] });
        // await this.getOffer();
      }
    }

    // Check if the state has changed
    if (
      prevState.bankIdsList !== this.state.bankIdsList ||
      prevState.categoryIdsList !== this.state.categoryIdsList
    ) {
      if (
        this.state.bankIdsList?.length > 0 ||
        this.state.categoryIdsList?.length > 0
      ) {
        this.setState({ currentPageNo: 1 });
        await this.getOffersFilterByMultiple();
      } else {
        await this.getOffer();
      }
    }
  }

  checkIsFavorite = (offerId) => {
    const { userFavoritesList } = this.state;
    let isAvailable = false;

    if (userFavoritesList && userFavoritesList.length > 0) {
      for (let i in userFavoritesList) {
        if (userFavoritesList[i]?.offerId === offerId) {
          isAvailable = true;
        }
      }
    }
    return isAvailable;
  };

  onClickSelectBank = (i) => {
    const { bankData } = this.state;
    bankData[i].isActive = !bankData[i].isActive;
    this.setState({ bankData });
    let updateData = [...this.state.bankData];
    if (updateData[i].isActive) {
      this.setState((prevState) => {
        let bankIdsList = [...prevState.bankIdsList, updateData[i].bankId];
        return { bankIdsList };
      });
    } else {
      this.setState((prevState) => {
        let bankIdsList = [
          ...prevState.bankIdsList.filter((id) => id !== updateData[i].bankId),
        ];
        return { bankIdsList };
      });
    }
  };

  onClickSelectCategory = (i) => {
    const { categoryData } = this.state;
    categoryData[i].isActive = !categoryData[i].isActive;
    this.setState({ categoryData });
    let updateData = [...this.state.categoryData];
    if (updateData[i].isActive) {
      this.setState((prevState) => {
        let categoryIdsList = [
          ...prevState.categoryIdsList,
          updateData[i].categoryId,
        ];
        return { categoryIdsList };
      });
    } else {
      this.setState((prevState) => {
        let categoryIdsList = [
          ...prevState.categoryIdsList.filter(
            (id) => id !== updateData[i].categoryId
          ),
        ];
        return { categoryIdsList };
      });
    }
  };

  render() {
    const {
      isSignUp,
      isSignIn,
      isCreateNewOffer,
      location,
      isLoadingMoreOffer,
      selectOfferData,
    } = this.props;
    const {
      pathName = this.props.location.pathname,
      categoryData,
      pageViewCount,
      sessionUser,
      isMobileVerification,
      bankIdsList,
      categoryIdsList,
      isFavoriteLoading,
    } = this.state;

    const profileMenu = (
      <Menu>
        <Menu.Item>
          <a target="_blank" rel="noopener noreferrer">
            Setting
          </a>
        </Menu.Item>
        <Menu.Item>
          <a
            rel="noopener noreferrer"
            onClick={() => this.props.onClickOpenProfileView()}
          >
            Profile
          </a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={() => this.onClickLogOut()} rel="noopener noreferrer">
            Logout
          </a>
        </Menu.Item>
      </Menu>
    );

    const {
      dataForm,
      isScrollTop,
      viewPointWidth,
      viewPointHeight,
      bankData,
      currentPageNo,
      offerId,
      onClickOpenSignIn,
      userFavoritesList,
      favoriteOffersList,
      settings,
    } = this.state;
    const {
      offerData,
      isLoading,
      totalCount,
      pageNo,
      noOfPage,
      privateUser,
      logInUser,
    } = this.props;

    return (
      <>
        <Helmet>
          <title>
            Best Credit Card Offers & Deals in Sri Lanka | Hotel & Supermarket Discounts – CreditCardOffers.lk
          </title>
          <meta
            name="description"
            content="Discover exclusive credit card deals, discounts, and rewards in Sri Lanka. Explore offers from top banks all in one place at CreditCardOffers.lk!."
          />
          <meta
            name="keywords"
            content="offers, creditcard offers, credit card offers, credit card deals, credit card promotion, hotel offers, hsbc credit card offers"
          />
          <link rel="canonical" href="https://www.creditcardoffers.lk" />
        </Helmet>

        <Helmet>
          <script type="application/ld+json">
            {`
                        {
                          "@context": "https://schema.org",
                          "@type": "WebPage",
                          "name": " Best Credit Card Offers & Deals in Sri Lanka | Hotel & Supermarket Discounts – CreditCardOffers.lk",
                          "url": "https://www.creditcardoffers.lk",
                          "description": "Discover exclusive credit card deals, discounts, and rewards in Sri Lanka. Explore offers from top banks all in one place at CreditCardOffers.lk!",
                          "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://www.creditcardoffers.lk"
                          }
                        }
                        `}
          </script>
        </Helmet>
        {/*join */}
        {/*{isSignIn && <SignInComponent />}*/}
        {/*<SignUpComponent />*/}
        {/*<OTPVerification />*/}
        {/*{isCreateNewOffer && <CreateNewOfferComponent />}*/}
        <OfferViewModal />
        <MoreInfo />
        <MobileProfileViewModal />
        <MobileSignInModal />
        <FilterViewModal />
        <ProfileView />
        <MainHeader
          logInUser={
            sessionUser && sessionUser?.userId && sessionUser?.userType
              ? sessionUser
              : null
          }
          history={this.props.history}
          privateUser={require("../../assets/img/default-avatar.jpg")}
          isScrollTop={isScrollTop}
          profileMenu={profileMenu}
          favoriteList={favoriteOffersList}
          onClickOpenNewOffer={() => this.props.onClickOpenNewOffer()}
          isLogin={this.props.isLogin}
          onClickSignUp={() => this.props.onClickOpenSignUp()}
          onClickLogOut={() => this.onClickLogOut()}
          onClickSignIn={() => this.props.onClickOpenSignIn()}
        />
        {/*/!*email verification failed information box*!/*/}
        {/*{this.props.isLogin &&*/}
        {/*<InfomationBox*/}
        {/*    type={'full-width'}*/}
        {/*    icon={<RiErrorWarningLine/>}*/}
        {/*    buttonIcon={<RiLinksLine/>}*/}
        {/*    messageType={'emailVerification'}/>*/}
        {/*}*/}
        {/*profile verification information box*/}
        {/*{logInUser && !logInUser?.isMobileVerification && (*/}
        {/*  <InfomationBox*/}
        {/*    type={"full-width"}*/}
        {/*    tag={"processing"}*/}
        {/*    title={"Business Verification Review"}*/}
        {/*    message={*/}
        {/*      "Your profile has been submitted & will be reviewed by our team."*/}
        {/*    }*/}
        {/*    icon={<RiHomeGearLine />}*/}
        {/*    buttonIcon={<RiLinksLine />}*/}
        {/*    messageType={"businessVerification"}*/}
        {/*    onClick={async () => {*/}
        {/*      if (sessionUser && sessionUser.userId) {*/}
        {/*        const token = getToken();*/}

        {/*        let res = await newOTPVerify(*/}
        {/*          sessionUser.userId,*/}
        {/*          "MOBILE",*/}
        {/*          token,*/}
        {/*          sessionUser.mobileNo*/}
        {/*        );*/}
        {/*        if (res && res.data && res.data.status) {*/}
        {/*          this.props.history.push("/verify-mobileNo");*/}
        {/*          this.props.openSignupOTP({*/}
        {/*            user: sessionUser,*/}
        {/*            token: token,*/}
        {/*          });*/}
        {/*        }*/}
        {/*      } else {*/}
        {/*        this.props.history.push("/sign-up");*/}
        {/*      }*/}
        {/*    }}*/}
        {/*  />*/}
        {/*)}*/}
        <div className={"console-body-wrapper "}>
          {/* <Scrollbars
            style={{ height: viewPointHeight - 10 }}
            thumbMinSize={30}
            universal={true}
            autoHide
          > */}
          {/*email verification*/}
          {location.pathname === "/" && (
            <div
              className={`console-inner-wrapper ${
                !isMobileVerification ? "active-info-bar" : "console"
              } `}
              style={{
                minHeight: viewPointHeight - 600,
              }}
            >
              <PreLoader isLoading={isLoading} />
              {/* <OfferSearch
                isActiveFilter={this.props.isOpen}
                isScrollTop={isScrollTop}
                bankData={bankData}
                categoryData={categoryData}
              /> */}
              {/*<div>*/}
              {/* <FilterSearch
                bankList={bankData}
                categoryList={categoryData}
                isScrollTop={this.state.isScrollTop}
              /> */}
              {/*</div>*/}
              <div className={"container"}>
                <div className={"row"}>
                  <div className={"col-12 col-lg-9 col-sm-12 col-md-9 mf-0"}>
                    <div
                      className={"offer-view-wrapper d-flex flex-column mb-3"}
                    >
                      {/*{!isLoading && <>*/}
                      {/*  {totalCount === 0 && <StatusVector status={'offerEmpty'}/>}</>}*/}
                      <PreLoader isLoading={isLoading} />
                      {/*==== OFFER VIEW CARD====*/}

                      {/* <div className="cco__mobile-recent-view-banks">
                        <MobileRecentViewBank
                          history={this.props.history}
                          isLoading={this.props.isLoading}
                        />
                      </div> */}
                      <div className="cco__recent-view-bank">
                        <RecentViewBank
                          history={this.props.history}
                          isLoading={this.props.isLoading}
                        />
                      </div>
                      <div className="cco__recent-view-offrs">
                        <RecentViewOffers history={this.props.history} />
                      </div>

                      <div className={"cco__offer-card-title"}>
                        <SectionHeader text={"All offers"} size={"md"} />
                      </div>
                      {isLoading && (
                        <>
                          <OfferShimmerCard />
                          <OfferShimmerCard />
                          <OfferShimmerCard />
                        </>
                      )}
                      <amp-ad
                        width="100vw"
                        height="320"
                        type="adsense"
                        data-ad-client="ca-pub-6407706631343407"
                        data-ad-slot="7958414514"
                        data-auto-format="rspv"
                        data-full-width=""
                      >
                        <div overflow=""></div>
                      </amp-ad>
                      {(offerData || []).map((item, index) => (
                        <OfferCard
                          key={item.offerId}
                          id={`${item.offerId}`}
                          animate={"animate__fadeIn"}
                          isFavorites={this.checkIsFavorite(item.offerId)}
                          isLoading={isFavoriteLoading}
                          onClick={async () => {
                            console.log("click_offer_view", item);
                            // const cardElement = document.getElementById(
                            //   `offer-card-${item.offerId}`
                            // );
                            // const scrollPosition = cardElement.offsetTop;
                            // sessionStorage.setItem(
                            //   "scrollPosition",
                            //   scrollPosition
                            // );

                            this.props.history.push(
                              `/credit-card-offers/${item.offerId}/${item.bank?.bankName}/${item.category?.categoryName}`
                            );
                            // this.props.onClickViewMoreOfferDetails(
                            //   item,
                            //   categoryData.filter(
                            //     (x) => x.categoryId === item.categoryId
                            //   ),
                            //   bankData.filter((x) => x.bankId === item.bankId)
                            // );

                            //========= createNewImpression =========
                            const position = await getGeoLocation();
                            console.debug("position", position);
                            const { latitude, longitude } = position?.coords;
                            const insight = await getInsight();
                            const [location, ip] = await Promise.all([
                              getLocationName(latitude, longitude),
                              getIPAddress(),
                            ]);
                            let state = userDetails();
                            console.debug("Insight:", insight);
                            let impressionRes = await createNewImpression({
                              merchantId: item?.merchantId,
                              offerId: item?.offerId,
                              categoryId: item?.categoryId,
                              bankId: item?.bankId,
                              latitude: position?.coords?.latitude,
                              longitude: position?.coords?.longitude,
                              location: location,
                              ip: ip,
                              user: state?.userId,
                              insightId: insight?.insightId,
                            });
                            console.info("impression_res", impressionRes);
                            ///==================================================
                          }}
                          categoryData={categoryData.filter(
                            (x) => x.categoryId === item.categoryId
                          )}
                          bankData={bankData.filter(
                            (x) => x.bankId === item.bankId
                          )}
                          data={item}
                          onClickFavorite={async () => {
                            this.setState({
                              isFavoriteLoading: true,
                            });
                            let isAvailable = this.checkIsFavorite(
                              item.offerId
                            );
                            let res = null;
                            if (isAvailable) {
                              res = await removeOfferFromUserFavorite(
                                item.offerId
                              );
                              openNotification({
                                title: "Favorite",
                                message:
                                  "Successfully removed the offer from your favorites.",
                                type: "success",
                              });
                            } else {
                              res = await addOfferToUserFavorite(
                                item.offerId,
                                item.categoryId,
                                item.merchantId,
                                item.bankId
                              );
                              openNotification({
                                title: "Favorite",
                                message:
                                  "Successfully added the offer to your favorites.",
                                type: "success",
                              });
                            }
                            if (res && res.data && res.data.status) {
                              await this.updateOffers();
                              this.props.onAddLoginUser(res?.data?.data?.user);

                              let user = res.data.data.user;
                              this.setState({
                                userFavoritesList: user?.favorites,
                                isFavoriteLoading: false,
                              });
                            } else {
                              this.setState({
                                isFavoriteLoading: false,
                              });
                              openNotification({
                                title: "One time password",
                                message:
                                  "Invalid authentication, Please check again OTP",
                                type: "danger",
                                container: "top-center",
                              });
                            }
                          }}
                        />
                      ))}
                      {/*==== LOADING MORE DATA ====*/}
                      {!isLoading &&
                        totalCount - pageNo * this.state.pageViewCount > 0 && (
                          <div
                            className={
                              "console__load-more-wrp d-flex justify-content-center mt-3 "
                            }
                          >
                            <Button
                              size={"xl"}
                              type={"light"}
                              isLoading={isLoadingMoreOffer}
                              onClick={async () => {
                                if (
                                  bankIdsList?.length > 0 ||
                                  categoryIdsList?.length > 0
                                ) {
                                  await this.getMoreOfferMultiple();
                                } else {
                                  await this.getMoreOffer();
                                }
                              }}
                              text={`Load more offers (${
                                totalCount - pageNo * this.state.pageViewCount
                              })`}
                            />
                          </div>
                        )}
                    </div>
                  </div>

                  {/*---- advertising area ----*/}
                  <div className={"col-12 col-lg-3 col-sm-12 col-md-3 "}>
                    {/* <div className={"advertising-wrapper"}>
                      <img
                        className={"offer-portal-logo"}
                        src={require("../../assets/img/ooge477x700_v1.png")}
                        alt="credit card offers"
                      />
                    </div> active-fix */}
                    <div
                      className={`advertisement-wrapper mt-4 ${
                        isScrollTop ? "" : ""
                      }`}
                    >
                      <div className={"adv-title d-flex flex-column"}>
                        <RestaurantAdCard
                          isImage={true}
                          title={"ooge"}
                          description={
                            "Transform your restaurant's reservation system with ooge."
                          }
                          link={"Sponsored . www.ooge.lk"}
                        />
                      </div>
                      <div id="container-84da2257d15632efb454470886829a5a"></div>
                    </div>

                    {/*<div className={"filter-wrapper"}>*/}
                    {/*  <div className={"fw__header"}>*/}
                    {/*    <div className={"fw__h-title"}>Filter</div>*/}
                    {/*    <Input*/}
                    {/*        elementConfig={dataForm.filter.elementConfig}*/}
                    {/*        elementType={dataForm.filter.elementType}*/}
                    {/*        required={dataForm.filter.validation.required}*/}
                    {/*        invalidReason={dataForm.filter.invalidReason}*/}
                    {/*        isinvalid={!dataForm.filter.valid}*/}
                    {/*        touched={dataForm.filter.touched}*/}
                    {/*        value={dataForm.filter.value}*/}
                    {/*        size={"lg"}*/}
                    {/*        margin={"m-t-0"}*/}
                    {/*        label={dataForm.filter.label}*/}
                    {/*        changed={(event) =>*/}
                    {/*            this.inputHandleChange(event, dataForm.filter.key)*/}
                    {/*        }*/}
                    {/*    />*/}
                    {/*  </div>*/}

                    {/*  <div className={"fw__title"}>Bank Name</div>*/}
                    {/*  {(bankData || []).map((item, index) => (*/}
                    {/*      <CheckBox*/}
                    {/*          onClick={() => {*/}
                    {/*            this.onClickSelectBank(index);*/}
                    {/*          }}*/}
                    {/*          text={item.bankName}*/}
                    {/*          type={"check"}*/}
                    {/*          isState={false}*/}
                    {/*          icon={""}*/}
                    {/*          isCheck={item.isActive}*/}
                    {/*          checkIcon={GetIcon('check')}*/}
                    {/*      />*/}
                    {/*  ))}*/}
                    {/*  <div className={"fw__title"}>Category</div>*/}
                    {/*  {(categoryData || []).map((item, index) => (*/}
                    {/*      <CheckBox*/}
                    {/*          onClick={() => {*/}
                    {/*            this.onClickSelectCategory(index);*/}
                    {/*          }}*/}
                    {/*          text={item.categoryName}*/}
                    {/*          type={"check"}*/}
                    {/*          isState={false}*/}
                    {/*          icon={""}*/}
                    {/*          isCheck={item.isActive}*/}
                    {/*          checkIcon={GetIcon('check')}*/}
                    {/*      />*/}
                    {/*  ))}*/}

                    {/*</div>*/}
                  </div>
                </div>
              </div>
            </div>
          )}
          {/*{location.pathname === `/offer-view/${offerId}` && (*/}
          {/*  <>*/}
          {/*    <ViewOffer />*/}
          {/*  </>*/}
          {/*)}*/}

          <Switch>
            <Route path="/console/merchant">
              {this.props.isLogin && (
                <MerchantPortalComponent
                  pathName={pathName}
                  isLogin={this.props.isLogin}
                  logInUser={this.props.logInUser}
                  privateUsers={this.props.privateUser}
                  onClickGoToPage={(link) => this.onClickGoToPage(link)}
                  viewPointWidth={viewPointWidth}
                  viewPointHeight={viewPointHeight}
                />
              )}
            </Route>

            <Route path="/console/customer">
              {this.props.isLogin && (
                <CustomerPortalComponent
                  pathName={pathName}
                  isLogin={this.props.isLogin}
                  logInUser={this.props.logInUser}
                  onClickGoToPage={(link) => this.onClickGoToPage(link)}
                  viewPointWidth={viewPointWidth}
                  viewPointHeight={viewPointHeight}
                />
              )}
            </Route>

            <Route path="/credit-card-offers/:offerId/:bank/:category">
              <ViewOffer
                history={this.props.history}
                onClickGoToPage={(link) => this.onClickGoToPage(link)}
              />
            </Route>
            <Route path="/credit-card-offers/:bank/:bankId/:category/:categoryId">
              <ViewBank
                history={this.props.history}
                onClickGoToPage={(link) => this.onClickGoToPage(link)}
              />
            </Route>
            <Route>
              <errorPage />
            </Route>
          </Switch>

          {/* <FooterSection /> */}

          <div>
            <Footer />
          </div>
          {/* </Scrollbars> */}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isSignUp: state.form.signUp,
    isSignIn: state.form.signIn,
    logInUser: state.user.data,
    isLogin: state.user.isLogin,
    isRegister: state.form.isRegister,
    isCreateNewOffer: state.form.isCreateNewOffer,
    privateUser: state.user.privateUser,
    isOpen: state.filter.isOpen,
    bankIdList: state.offer.bankIdList,
    categoryIdList: state.offer.categoryIdList,
    type: state.filter.type,
    offerData: state.offer.offerData,
    isLoading: state.offer.isLoading,
    pageNo: state.offer.pageNo,
    noOfPage: state.offer.noOfPage,
    totalCount: state.offer.totalCount,
    isLoadingMoreOffer: state.offer.isLoadingMoreOffer,
    selectOfferData: state.form.offerData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllCategory: () => dispatch(getAllCategory()),
    getLoginUserDetailsById: (id) => dispatch(getLoginUserDetailsById(id)),
    getLoginDetails: () => dispatch(getLoginDetails()),
    onAddLoginUser: (userDara) =>
      dispatch({ type: actionTypes.ADD_LOGIN_USER, loginUser: userDara }),
    userLogged: () => dispatch({ type: actionTypes.USER_LOGGED }),
    onClickLogOut: () => dispatch({ type: actionTypes.LOG_OUT }),
    onClickOpenSignUp: () => dispatch({ type: actionTypes.OPEN_SIGN_UP }),
    onClickOpenSignIn: () => dispatch({ type: actionTypes.OPEN_SIGN_IN }),
    onClickOpenNewOffer: () => dispatch({ type: actionTypes.CREATE_NEW_OFFER }),
    onClickViewMoreOfferDetails: (payload, categoryData, bankData) =>
      dispatch({
        type: actionTypes.OPEN_OFFER_VIEW_MODAL,
        payload: payload,
        categoryData: categoryData,
        bankData: bankData,
      }),

    onLoadOffers: () => dispatch({ type: actionTypes.ON_LOAD_OFFER }),
    onLoadingMoreOffers: () =>
      dispatch({ type: actionTypes.ON_LOADING_MORE_OFFER }),
    onLoadingMoreOffersCompleted: () =>
      dispatch({ type: actionTypes.ON_LOADING_MORE_OFFER_COMPLETE }),
    onCompleteOffers: (payload) =>
      dispatch({
        type: actionTypes.ON_COMPLETE_LOAD_OFFER,
        payload: payload,
      }),
    loadMoreOffers: (payload) =>
      dispatch({
        type: actionTypes.ON_LOAD_MORE_OFFER,
        payload: payload,
      }),
    setOffersByFilter: (payload) =>
      dispatch({
        type: actionTypes.SET_OFFER_DATA_FILTER,
        payload: payload,
      }),
    selectAllCategory: (selectedData) =>
      dispatch({
        type: actionTypes.SELECTED_CATEGORY_GROUP,
        sData: selectedData,
      }),
    openSignupOTP: (payload) =>
      dispatch({ type: actionTypes.OPEN_OTP_VERIFY, payload: payload }),
    setUserInsight: (payload) =>
      dispatch({
        type: actionTypes.SET_USER_INSIGHT,
        payload: payload,
      }),
    setBank: (payload) =>
      dispatch({
        type: actionTypes.SET_ALL_BANK,
        payload: payload,
      }),
    setCategory: (payload) =>
      dispatch({
        type: actionTypes.SET_ALL_CATEGORY,
        payload: payload,
      }),
    onClickOpenProfileView: (payload) =>
      dispatch({ type: actionTypes.OPEN_PROFILE_VIEW, payload: payload }),
    clearFilterOptions: () => dispatch({ type: actionTypes.CLEAR_ALL_FILTER }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConsoleComponent);
