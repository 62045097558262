import React from "react";
import "./styles.scss";
//import "./mobile.scss";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { OfferTypeTag } from "../..";

const Index = (props) => {
  return (
    <>
      {!props.isLoading && (
        <div
          className={`offer-favorite-card-wrapper flex-column justify-content-center align-items-center`}
          onClick={props.onClick}
        >
          {props.status === "expireSoon" && (
            <div className={"ofcw__tag"}>
              <OfferTypeTag text={"Ending soon"} type={"ending-soon"} />
            </div>
          )}
          {props.status === "new" && (
            <div className={"ofcw__tag"}>
              <OfferTypeTag text={"New"} type={"new"} />
            </div>
          )}

          {/* banner start */}
          <div className={"ofcw__banner-wrapper"}>
            <img src={props.bannerUrl} alt="credit card offers" />
            {/* title start */}
            <div
              className={
                "ofcw__title d-flex flex-column justify-content-center align-items-center"
              }
            >
              <div className={"ofcw__t-merchant-logo"}>
                <img src={props.logoUrl} alt="creditcard offers" />
              </div>
              {/* <div className={"d-flex flex-column"}> */}
              <div className={"ofcw__t-merchant-name"}>
                {props.merchantName}
              </div>
              {/* <div className={"ofcw__t-header"}> */}
              {/* {props.header.length > 70
                ? props.header.substring(0, 70).trimEnd() + "..."
                : props.header} */}
              {/* {props.header}
              </div> */}
              <div className={"ofcw__location-tag"}>{props.location}</div>
              {/* </div> */}
            </div>
            {/* title end */}

            <div className={"ofcw__bw-white-layer"} />
          </div>
        </div>
      )}

      {props.isLoading && (
        <>
          <div className={`offer-favorite-card-shimmer flex-column `}>
            <div className={"ofcs__location-tag-shimmer shimmerBG"}></div>

            {/* banner start */}
            <div className={"ofcs__banner-shimmer "}>
              {/* title start */}
              <div className={"ofcs__bs-title-shimmer d-flex flex-column"}>
                <div
                  className={"ofcs__bsts-merchant-logo-shimmer shimmerBG"}
                ></div>
                <div
                  className={"ofcs__bsts-merchant-name-shimmer shimmerBG"}
                ></div>
                <div className={"ofcs__bsts-header-shimmer shimmerBG"}></div>
              </div>
              {/* title end */}
            </div>
          </div>
        </>
      )}
    </>
  );
};

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
